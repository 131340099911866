import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

import {
  headerHeight,
  mobileHeaderHeight,
  sidebarWidth,
  tabletBreakPoint,
  transitionDelay,
} from "../../constants/size";

const SidebarLayoutStyledComponents = {
  Container: styled.div`
    height: 100%;
    display: flex;
    padding-top: ${headerHeight}px;
    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      padding-top: ${mobileHeaderHeight}px;
    }
  `,

  SideBar: styled.div`
    width: ${sidebarWidth}px;
    height: calc(100% - ${headerHeight}px);

    overflow: auto;

    position: fixed;
    left: 0;
    z-index: 10;

    ${getColor("background", "white", "black")};

    border-right: 1px solid;
    ${getColor("border-right-color", "gray200", "gray800")}
    h2 {
      padding: 40px 32px 26px;

      ${typography.heading04B};
    }

    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      left: -100%;
      height: calc(100% - ${mobileHeaderHeight}px);
    }
  `,

  Main: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    z-index: 0;
    margin-left: ${sidebarWidth}px;

    transition: margin-left ${transitionDelay}s;

    ${getColor("background", "white", "black")}

    @media (max-width: ${tabletBreakPoint}px) {
      margin-left: 0px;
    }
  `,
  Footer: styled.footer`
    width: 100%;
    height: 100px;

    padding: 54px 0 32px 40px;
    margin-top: auto;

    border-top: 1px solid;
    ${getColor("border-top-color", "gray200", "gray800")}

    p {
      max-width: 1080px;
      margin: auto;
      font-size: 12px;
      ${getColor("color", "black")}
    }
    @media (max-width: ${tabletBreakPoint}px) {
      display: flex;
      align-items: center;

      padding: 42.5px 0 42.5px 24px;

      line-height: normal;
      font-weight: 500;
    }
  `,
};

export default SidebarLayoutStyledComponents;
