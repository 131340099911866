"use client";

import { FC, useContext } from "react";
import { usePathname } from "next/navigation";

import { SidebarLayoutProps } from "./types";

import SidebarLayoutStyledComponents from "./styled";
import { MenuContext } from "../Layout/context";

import SidePanelMenu from "../SidePanelMenu";
import { getParentMenus } from "../../utils/network/menu";

const { Container, SideBar, Main, Footer } = SidebarLayoutStyledComponents;

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  const pathname = usePathname();
  const { menus } = useContext(MenuContext);

  const domain = pathname.split("/")[1];

  const parentMenus = getParentMenus(menus);

  const parentMenu = parentMenus.find(({ path }) => path.includes(domain));

  const childrenMenus = menus.filter(({ id }) =>
    parentMenu?.children.includes(id)
  );

  return parentMenu ? (
    <Container>
      <SideBar>
        <h2>{parentMenu.title}</h2>
        <SidePanelMenu currentPath={pathname} items={childrenMenus} />
      </SideBar>
      <Main>
        {children}
        <Footer>
          <p>© R114 Corporation</p>
        </Footer>
      </Main>
    </Container>
  ) : null;
};

export default SidebarLayout;
